<style src="./accordion-item.component.scss" lang="scss"></style>

<template>
  <div class="accordion-item" :class="{'accordion-item__active':item2}">
    <div class="accordion-item__title" @click="accordionClick">
      <slot name="title"></slot>
    </div>
    <transition>
      <div class="accordion-item__wrapper" ref="wrapper">
        <div class="accordion-item__content" ref="content">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script src="./accordion-item.component.ts"></script>
