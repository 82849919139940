import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import OrdersListComponent
    from '@/pages/information-electronic-orders-service/components/orders-list/orders-list.component.vue';
import {container} from 'tsyringe';
import {
    InformationElectronicOrdersServiceSandbox
} from '@/pages/information-electronic-orders-service/sandbox/information-electronic-orders-service.sandbox';
import {ElectronOrders} from '@/pages/information-electronic-orders-service/types/electron-orders';
import {store} from '@/store';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {IServiceCommon} from '@/types/service-common';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {IServiceHeader} from '@/types/service-header';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'information-electronic-orders-service-component',
    components: {
        OrdersListComponent,
        CustomerInfoComponent,
        NotDataComponent,
        ServiceNameComponent
    }
})

export default class InformationElectronicOrdersServiceComponent extends mixins(Router) {
    public sandbox = container.resolve(InformationElectronicOrdersServiceSandbox);
    public requestOrders = true;
    public store = store;
    public pageNumber = 1;
    public pageSize = 10;
    public service = 1145;

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get orders(): ElectronOrders[] {
        return store.getters._getOrders;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get userData() {
        if (this.store.getters._getUserFullData.document.length > 0 && this.requestOrders) {
            this.getServiceData();
            this.sandbox.getElectronOrders(this.splitDocument(this.store.getters._getUserFullData.document));
            this.requestOrders = false;
        }
        return this.store.getters._getUserFullData;
    }

    get totalCountOrders(): number {
        return store.getters._getTotalCountOrders;
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        const document = this.splitDocument(this.userData.document);
        let payload = {
            token: localStorage.getItem('mainToken'),
            docNumber: document.docNumber,
            docSerial: document.docSerial,
            pageSize: info.pageSize,
            pageNumber: info.pageNumber,
        };
        this.sandbox.getElectronOrders(payload);
        window.scroll(0, this.$el.querySelector('#table').offsetTop);
    }

    public splitDocument(data: string) {
        let payload = {
            docNumber: '',
            docSerial: '',
            token: localStorage.getItem('mainToken'),
        };
        if (data.length === 9) {
            payload.docSerial = data?.substr(0, 2);
            payload.docNumber = data?.substr(2, 8);
        }
        if (data.length === 11) {
            payload.docSerial = data?.substr(0, 3);
            payload.docNumber = data?.substr(3, 10);
        }
        return payload;
    }
}
