import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {IPhoneInformation} from '@/pages/information-phone-service/types/phone-information';
import AccordionItemComponent
    from '@/pages/information-electronic-orders-service/components/accordion-item/accordion-item.component.vue';
import OrderTitleComponent
    from '@/pages/information-electronic-orders-service/components/order-title/order-title.component.vue';
import {ElectronOrders} from '@/pages/information-electronic-orders-service/types/electron-orders';
import OrdersInfoComponent
    from '@/pages/information-electronic-orders-service/components/orders-info/orders-info.component.vue';

@Options({
    name: 'accordion-component',
    components: {
        AccordionItemComponent,
        OrderTitleComponent,
        OrdersInfoComponent
    },
    props: {
        orders: {
            type: Array,
        }
    }

})

export default class AccordionComponent extends Vue {
    public orders: ElectronOrders[] = [];
    public informationPhone: IPhoneInformation = {
        name: null,
        address: 'string | null',
        date: ' string | null',
        provider: ' string | null',
        service: ' string | null',
        junction: ' string | null',
        debts: [],
        payment:[]
    };
    public locale$: any = locale;
    public locale: any = null;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.elements;
            }
        });
    }

    public $refs!: {
        item: HTMLElement,
    };

    public async selectedNumber(selectNumber: any) {
        let payload = {
            token: localStorage.getItem('mainToken'),
            params: {
                number: selectNumber,
                pageNumber: 1,
                pageSize: 1
            }
        };
        // return await this.sandbox.getDetailedInfoService(payload);

    }


    public async clickItem($event: any, select: any) {
        if ($event.currentTarget) {
            if (select.isOpen) {
                select.isOpen = false;
            } else {
                select.isOpen = true;
            }
        }
    }
}
