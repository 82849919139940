import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import AccordionComponent
    from '@/pages/information-electronic-orders-service/components/accordion/accordion.component.vue';

@Options({
    name: 'orders-info-component',
    components: {
        AccordionComponent,
    },
    props: {
        order: {
            type: Object
        }
    }
})

export default class OrdersInfoComponent extends Vue {
    public order = {
        orderNumber: '',
        postIndex: '',
        streetName: '',
        addressFirst: '',
        addressSecond: '',
        selectedPhone: '',
        operationType: '',
        operationStatus: '',
        createDate: '',
        isOpen: false,
    };

    get information() {
        return [
            {
                name: 'Sorğu nömrəsi:',
                mine: this.order.orderNumber
            },
            {
                name: 'Poçt kodu:',
                mine: this.order.postIndex
            },
            {
                name: 'Küçə adı:',
                mine: `${this.order.streetName} ${this.order.addressFirst} ${this.order.addressSecond}`
            },
            {
                name: 'Seçdiyiniz nömrə:',
                mine: this.order.selectedPhone
            },
            {
                name: 'Tarix:',
                mine: this.order.createDate
            },
        ];

    }

}
