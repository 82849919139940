import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class InformationElectronicOrdersServiceSandbox {
    private store = store;

    constructor() {
    }

    getElectronOrders(payload: any): any {
        return this.store.dispatch('getElectronOrders', payload);
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }
}