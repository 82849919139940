import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import OrderTitleComponent
    from '@/pages/information-electronic-orders-service/components/order-title/order-title.component.vue';

@Options({
    name: 'accordion-item-component',
    props: {
        item: {
            type: [Boolean, null]
        }
    },
})

export default class AccordionItemComponent extends Vue {
    public item = false;
    public locale$: any = locale;
    public locale: any = null;
    public active = false;
    public selectItem = 0;
    public $refs!: {
        content: HTMLElement,
        wrapper: HTMLElement,
    };

    get item2(): boolean {
        // if (this.item) {
        //     this.accordionClick();
        // } else if (this.item !== null) {
        //     this.accordionClick();
        // }
        return this.item;
    }


    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.informationPhone) {
                this.locale = res.informationPhone;
            }
        });
    }


    public accordionClick() {
        let contentHeight = this.$refs.content.clientHeight,
            wrapperHeight = this.$refs.wrapper.clientHeight;
        if(this.item) {
            if (contentHeight != wrapperHeight) {
                this.$refs.wrapper.style.maxHeight = `${contentHeight + 15}px`;
            }
        } else {
            if (contentHeight < wrapperHeight) {
                this.$refs.wrapper.style.maxHeight = `0`;
            }
        }
    }
}
