import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'order-title-component',
    props: {
        name: {
            type: String
        },
        status: {
            type: String
        },
        statusId:{
            type: Number
        }
    }
})

export default class OrderTitleComponent extends Vue {
    // public name: string = '';
    // public status: string = '';
}
