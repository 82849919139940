<style src="./order-title.component.scss" lang="scss"></style>

<template>
  <div class="order-title">
    <div class="order-title__operation">
      <div class="order-title__operation__name-criteria">
        Əməliyyat növü:
      </div>
      <div class="order-title__operation__name-reply">
        {{ name }}
      </div>
    </div>
    <v-divider class="mt-24 mb-26 d-block d-lg-none"/>
    <div
        :class="[{
          'order-title__operation-status--green':statusId === 1,
          'order-title__operation-status--red':statusId === 2,
          'order-title__operation-status--orange':statusId === 3,
          'order-title__operation-status--blue':statusId === 4,
          'order-title__operation-status--dark-blue':statusId === 5

        },]"
        class="order-title__operation-status">
      {{ status }}
    </div>
  </div>
</template>

<script src="./order-title.component.ts"></script>
