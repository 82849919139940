<style src="./information-electronic-orders-service.component.scss" lang="scss"></style>

<template>
  <div class="information-electronic-orders">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-md-12">
          <customer-info-component :user="userData"/>
        </div>
        <div class="col-12 mb-100">
          <orders-list-component :orders="orders" class="mb-100" v-if="totalCountOrders!==0"/>
          <v-card  v-if="(totalCountOrders === 0) && userData.name?.length">
            <not-data-component class="mt-20">
              Sizin elektron sifarişiniz yoxdur
            </not-data-component>
          </v-card>

          <v-pagination
              v-if="totalCountOrders"
              ref="pagination"
              :limit="pageSize"
              :dataCount="totalCountOrders"
              :page="pageNumber"
              @changePage="changePage"
              class="mt-40 mb-100  "/>

        </div>
      </div>
    </div>
  </div>
</template>

<script src="./information-electronic-orders-service.component.ts"></script>
