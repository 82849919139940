import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {store} from '@/store';
import AccordionComponent
    from '@/pages/information-electronic-orders-service/components/accordion/accordion.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';

@Options({
    name: 'orders-list-component',
    components: {
        AccordionComponent,
        NotDataComponent
    },
    props: {
        orders: {
            type: Array
        }
    }
})

export default class OrdersListComponent extends Vue {
    public store = store;
    public width = 0;

    get userData() {
        return this.store.getters._getUserFullData;
    }

    public mounted() {
        this.width = window.innerWidth;
        window.addEventListener('resize', () => {
            this.width = window.innerWidth;
        });
    }


}
