<style src="./orders-list.component.scss" lang="scss"></style>

<template>
  <div class="orders-list">
    <div class="row">
      <div class="col-12">
        <v-card class="h-container" v-if="width>993">
          <accordion-component :orders="orders"/>
        </v-card>
        <accordion-component class="orders-list-mobile" v-else :orders="orders"/>
      </div>
    </div>
  </div>
</template>

<script src="./orders-list.component.ts"></script>
