<style src="./orders-info.component.scss" lang="scss"></style>

<template>
  <div class="orders-info">
    <div class="row">
      <div class="col-12 d-lg-block d-none mb-24">
        <v-divider/>
      </div>
      <div class="col-12 col-lg-4" v-for="(item) in information">
        <div class="orders-info__wrapper" >
          <div class="orders-info__criteria">
            {{ item.name }}
          </div>
          <div class="orders-info__reply mt-4">
            {{ item.mine }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./orders-info.component.ts"></script>
