<style src="./accordion.component.scss" lang="scss"></style>

<template>
  <div class="accordion">
    <accordion-item-component
        class="mb-20"
        v-for="item in orders"
        :item="item.isOpen"
    >
      <template v-slot:title>
        <order-title-component
            @click="clickItem($event,item)"
            :name="item.operationType"
            :statusId="item.operationStatusID"
            :status="item.operationStatus"/>
      </template>
      <template v-slot:content>
        <orders-info-component :order="item"/>
      </template>
    </accordion-item-component>
  </div>
</template>

<script src="./accordion.component.ts"></script>
